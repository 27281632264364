<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-input-search
      placeholder="全局搜索"
      style="width: 300px;"
      v-model="globalSearch"
      @search="onSearch"
    />
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px;"
      size="small"
      :loading="loading"
      :rowKey="(row) => row.id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <span slot="task" slot-scope="text">
        {{ text.name }}
      </span>
      <span slot="plugin" slot-scope="text">
        {{ text.name }}
      </span>
      <span slot="create_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot="update_time" slot-scope="text">
        {{ changeTime(text) }}
      </span>
      <span slot="plugin_check" slot-scope="text">
        <a-tag :color="text | taskHitColor">{{
          text | taskHitText
        }}</a-tag>
      </span>
      <p slot="expandedRowRender" slot-scope="text" style="margin: 0">
        <pre>{{ "原始流量:\n" + text.origin_data + (text.payload_info ? "\n" + "payload_info\n" + text.payload_info : "") }}</pre>
      </p>
    </a-table>
  </a-card>
</template>

<script>
import { getTaskDetail } from "@/api/task";
import task_consts from "@/constants/task";
import { changeTime } from "@/libs/utils";
export default {
  data() {
    this.changeTime = changeTime;
    return {
      task_id: 0,
      loading: false,
      data: [],
      globalSearch: "",
      columns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          scopedSlots: { customRender: "id" },
          width: 80,
          sorter: true,
        },
        {
          title: "任务名称",
          dataIndex: "task",
          ellipsis: true,
          scopedSlots: { customRender: "task" },
          width: 80,
        },
        {
          title: "目标",
          dataIndex: "target",
          ellipsis: true,
          scopedSlots: { customRender: "target" },
          width: 80,
        },
        {
          title: "插件名称",
          dataIndex: "plugin",
          ellipsis: true,
          scopedSlots: { customRender: "plugin" },
          width: 80,
        },
        {
          title: "结果",
          dataIndex: "plugin_check",
          ellipsis: true,
          scopedSlots: { customRender: "plugin_check" },
          width: 80,
          filters: [
            {
              text: "扫描中",
              value: 0
            },
            {
              text: "命中",
              value: 1,
            },
            {
              text: "未命中",
              value: 2,
            }
          ],
          filterMultiple: false,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          ellipsis: true,
          scopedSlots: { customRender: "create_time" },
          width: 80,
          sorter: true,
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          ellipsis: true,
          scopedSlots: { customRender: "update_time" },
          width: 80,
          sorter: true,
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        current: 1,
        pageSize: 10,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      // 过滤
      let filterDic = {};
      for (var i in filters) {
        filterDic[i] = filters[i][0];
      }
      // 排序
      let orderingDic = {};
      if (sorter.order) {
        orderingDic["ordering"] =
          `${sorter.order === "ascend" ? "" : "-"}` + sorter.field;
      }
      this.fetch({...filterDic, ...orderingDic});
    },
    onSearch () {
      this.fetch();
    },
    fetch(query={}) {
      this.loading = true;
      if (this.task_id !== 0) {
        query["task__id"] = this.task_id;
      }
      if (this.globalSearch) {
        query["search"] = this.globalSearch;
      }
      query["page_size"] = this.pagination.pageSize;
      query["page"] = this.pagination.current;
      getTaskDetail(query)
      .then((res) => {
        const pagination = { ...this.pagination };
        pagination.total = parseInt(res.data.count);
        this.data = res.data.results;
        this.pagination = pagination;
      })
      .catch((err) => {
        this.$message.error(err.toString());
      });
      this.loading = false;
    }
  },
  filters: {
    taskHitText (type) {
      return task_consts.taskHitList[type].label;
    },
    taskHitColor (type) {
      return task_consts.taskHitList[type].color;
    },
  },
  mounted() {
    var task_id = this.$route.query.id;
    if (task_id !== undefined) {
      this.task_id = task_id;
    }
    this.fetch();
  },
};
</script>